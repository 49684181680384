import tooltip from './v-tooltip';

export { tooltip };

// Wraps all directives in a Vue plugin ( Vue.use() )
export default {
    install(Vue) {
        Vue.directive('tooltip', tooltip);
    },
};
