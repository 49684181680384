<template>
    <li class="nav-item" :class="{ active: $route.name === route }">
        <router-link class="nav-link" :to="{ name: route }">
            <div class="d-flex align-items-center">
                <span class="nav-link-icon"><span :class="['fas', 'fa-fw', icon]" /></span>
                <span class="nav-link-text">{{ name }}</span>
            </div>
        </router-link>
    </li>
</template>

<script>
    export default {
        name: 'NavItem',
        props: {
            name: {
                type: String,
                required: true,
            },
            icon: {
                type: String,
                required: true,
            },
            route: {
                type: String,
                required: true,
            },
        },
    };
</script>
