import { auth0 } from './auth0';
import axios from 'axios';

// This file only performs side effects!

// The interceptor modifies Axios to make API requests to the SIMP backend by
// 1) setting the base URL to the API's URL, and
// 2) including the current user's access token with every request

axios.interceptors.request.use(async config => {
    config.baseURL = process.env.VUE_APP_API_BASE;

    const accessToken = await (await auth0).getAccessToken();
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});
