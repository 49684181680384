import * as Sentry from '@sentry/vue';
import router from '.';

export const authRoutes = [
    {
        name: 'login',
        path: '/login',
        component: () => import('../views/Login.vue'),
        meta: { noNav: true },
        async beforeEnter(_to, _from, next) {
            if (await router.app.$auth.isAuthenticated()) {
                next({ path: '/' });
            } else {
                next();
            }
        },
    },
    {
        name: 'initiate-auth0-login',
        path: '/initiate-login',
        beforeEnter() {
            router.app.$auth.login();
        },
    },
    {
        path: '/authorized',
        meta: { noNav: true },
        async beforeEnter(to, _from, next) {
            try {
                await router.app.$auth.authorizedCallback(to.fullPath);
                next({ path: '/' });
            } catch (err) {
                Sentry.captureException(err);
                next({ name: 'login', params: { authError: true }});
            }
        },
    },
];

export const authGuard = async (to, _from, next) => {
    // Authentication-related routes should be exempt from checks
    const needsAuth = !['/login', '/initiate-login', '/authorized'].includes(to.path);

    if (!needsAuth) {
        return next();
    }

    if (!await router.app.$auth.isAuthenticated()) {
        return next({ name: 'login' });
    }

    if (hasPermissionsForRoute(to)) {
        return next();
    } else {
        return next({ path: '/' });
    }
};

const hasPermissionsForRoute = (route) => {
    const requiredPermissions = route.matched
        .flatMap(route => route.meta.requiredPermissions)
        .filter(permission => typeof permission === 'string' && permission.length > 0);

    return router.app.$auth.hasPermissions(...requiredPermissions);
};
