/**
 * Formats the input as US dollars (`3.5` => `'$3.50'`)
 * @param {Number} dollars Amount of currency in dollars
 * @returns {String} Formatted string
 */
export const formatDollars = dollars => (+dollars).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

/**
 * Formats the input as US cents (`350` => `'$3.50'`)
 * @param {Number} cents Amount of currency in cents
 * @returns {String} Formatted string
 */
export const formatCents = cents => formatDollars(cents / 100);
