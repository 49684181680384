<template>
    <div class="m-4 position-fixed b-0 l-0" style="z-index: 2000;">
        <!-- User feedback card -->
        <transition>
            <div v-if="showFeedback" class="card feedbackCard">
                <div class="m-3">
                    <form @submit.prevent="createFeedback()" id="feedbackForm">
                        <div class="form-group">
                            <label for="feedback">Send our team a question, comment, or bug report.</label>
                            <textarea id="feedback"
                                      type="text"
                                      class="form-control form-control-sm"
                                      style="resize: none;"
                                      rows="3"
                                      v-model="feedback"
                                      required />
                        </div>
                    </form>
                    <div class="d-flex">
                        <button @click="toggleUserFeedback()" class="btn btn-sm btn-light ml-auto mr-2" type="button">
                            Cancel
                        </button>
                        <button class="btn btn-sm btn-primary"
                                type="submit"
                                form="feedbackForm"
                                :disabled="creatingFeedback">
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </transition>
        <!-- Toggle feedback button -->
        <button @click="toggleUserFeedback()"
                class="d-flex border-0 rounded-circle bg-primary p-3 shadow"
                style="outline: none">
            <i class="fas fa-comment-alt m-auto text-white" />
        </button>
    </div>
</template>

<script>
    import { createUserFeedback } from '@/services/UserFeedbackService';

    export default {
        name: 'UserFeedback',
        data() {
            return {
                showFeedback: false,
                feedback: '',
                creatingFeedback: false,
            };
        },
        methods: {
            toggleUserFeedback() {
                this.showFeedback = !this.showFeedback;
                this.feedback = '';
            },
            async createFeedback() {
                try {
                    const feedbackData = {
                        feedback: this.feedback,
                        href: window.location.href,
                        platform: window.navigator.platform,
                        version: window.navigator.userAgent,
                    };
                    await createUserFeedback(feedbackData);
                    this.toggleUserFeedback();
                    this.$alerts.success('Message Sent', 'Thank you for your feedback, our team has been notified.');
                } catch (error) {
                    this.$alerts.danger('Failed to Send Message', 'Please contact support if this problem persists.');
                } finally {
                    this.creatingFeedback = false;
                }
            },
        },
    };
</script>

<style scoped>
.feedbackCard {
  position: absolute;
  width: 400px;
  bottom: 0;
}

.v-enter {
  opacity: 0;
  transform: translateX(-300px);
}

.v-enter-active {
  transition: opacity 0.6s, transform 0.6s;
}

.v-move {
  transition: transform 0.4s;
}

.v-leave-active {
  /* This is required for the move transitions to play on removal. It removes the element from flow, allowing
     the others to move into the new space. The regular way to do this is position: absolute, but that causes
     issues in a fixed-position context that aren't worth working around. */
  display: none;
}
</style>
