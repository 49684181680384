import * as Sentry from '@sentry/vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import Vue from 'vue';

// Auth0 SPA SDK API docs: https://auth0.github.io/auth0-spa-js/index.html

// Namespace that is used for any custom JWT claims
const claimNamespace = 'https://medshift.com/';

class Auth0 {
    constructor(options) {
        this._auth0Client = null;
        this.user = {};
        this.permissions = [];

        this._auth0Client = new Auth0Client(options);

        // Warning: black magic ahead
        return this._auth0Client.checkSession()
            .then(async () => {
                if (await this._auth0Client.isAuthenticated()) {
                    await this._setUserState();
                }

                // Make this object reactive so we can use it in components like a Vue data object
                // Using the returned object instead of relying on mutation is best practice according to the docs
                return Vue.observable(this);
            })
            .catch(err => {
                // Catches "Unknown or invalid refresh token," as well as anything else this throws.
                // Just logging the user out is easier than trying to handle the resulting bad state.
                Sentry.captureException(err);
                this._auth0Client.logout();
            });
    }

    async _setUserState() {
        this.user = await this._auth0Client.getUser();

        const idToken = await this._auth0Client.getIdTokenClaims();
        this.permissions = idToken[claimNamespace + 'permissions'] || [];
    }

    // Makes the instance installable as a Vue plugin (Vue.use())
    install(Vue) {
        Vue.prototype.$auth = this;
    }

    // CAVEAT: When using these methods in a component's v-on, you must use parentheses ($auth.foo() not $auth.foo)

    /**
     * Returns a boolean value indicating if the user is currently authenticated
     */
    async isAuthenticated() {
        return await this._auth0Client.isAuthenticated();
    }

    /**
     * Returns the user's access token
     */
    async getAccessToken() {
        return await this._auth0Client.getTokenSilently();
    }

    /**
     * Initiates a login flow by redirecting the user to Auth0's login page
     */
    login() {
        this._auth0Client.loginWithRedirect();
    }

    /**
     * Clears the local session and redirects the user to Auth0's logout page before returning them to our login page
     */
    logout() {
        this._auth0Client.logout({ returnTo: `${process.env.VUE_APP_BASE_URL}/login` });
    }

    /**
     * After the user logs in with Auth0, they will be redirected to a callback URL with authentication data in the
     * URL's query string. This method parses that data to complete the login process.
     * @param {string} url The callback URL from Auth0. Does not need to be the full path.
     */
    async authorizedCallback(url) {
        await this._auth0Client.handleRedirectCallback(url);

        // The user check in the constructor doesn't behave consistently between browsers. This extra call is needed
        // to keep things working consistently during login.
        await this._setUserState();
    }

    /**
     * Returns `true` iff the user has *all* of the specified permissions
     * @param {...string} permissions One or more permission strings
     */
    hasPermissions(...permissions) {
        return permissions.every(permission => this.permissions.includes(permission));
    }

    /**
     * Returns `true` if the user has *any* of the specified permissions
     * @param {...string} permissions One or more permission strings
     */
    hasSomePermission(...permissions) {
        return permissions.some(permission => this.permissions.includes(permission));
    }
}

// Shared instance
export const auth0 = new Auth0({
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH0_API_AUDIENCE,
    redirect_uri: `${process.env.VUE_APP_BASE_URL}/authorized`,
    authorizeTimeoutInSeconds: 15,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
});
