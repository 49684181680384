<template>
    <div class="nav-item dropdown py-2">
        <a class="nav-link user-box media align-items-center rounded text-700 p-1" data-toggle="dropdown" role="button">
            <div class="avatar avatar-xl">
                <img v-if="$auth.user.picture" class="rounded-circle" :src="$auth.user.picture" alt="">
                <div v-else class="avatar-name rounded-circle"><span><i class="fas fa-user" /></span></div>
            </div>
            <div class="media-body ml-2" style="line-height: 1.3">
                <div class="font-weight-normal fs--2">Signed in as</div>
                <div>{{ $auth.user.name || $auth.user.nickname || $auth.user.email }}</div>
            </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right py-0 mt-1 mr-2">
            <div class="bg-white rounded-soft py-2">
                <button class="dropdown-item" @click="$auth.logout()">Log Out</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UserNavItem',
    };
</script>

<style scoped>
    .user-box:hover, .dropdown.show .user-box {
        background-color: #d8e2ef;
    }
</style>
