import { auth0 } from '@/auth0';
import axios from 'axios';

const userFeedbackAxios = axios.create({
    baseURL: process.env.VUE_APP_API_BASE,
});

userFeedbackAxios.interceptors.request.use(async config => {
    try {
        const accessToken = await (await auth0).getAccessToken();
        config.headers.Authorization = `Bearer ${accessToken}`;
    } catch {
        /* ignore */
    }
    return config;
});

export const createUserFeedback = (data) => userFeedbackAxios.post('/user_feedback/', data);
