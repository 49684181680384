<template>
    <nav class="navbar navbar-vertical navbar-expand-xl navbar-light">
        <div class="d-flex align-items-center">
            <div class="toggle-icon-wrapper">
                <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Toggle Navigation">
                    <span class="navbar-toggle-icon"><span class="toggle-line" /></span>
                </button>
            </div>
            <router-link :to="{ path: '/' }" class="navbar-brand">
                <div class="d-flex align-items-center py-3">
                    <img src="/img/logo_color.svg" height="42px">
                </div>
            </router-link>
        </div>
        <div class="navbar-collapse collapse" id="navbarVerticalCollapse">
            <div class="navbar-vertical-content perfect-scrollbar scrollbar">
                <ul class="navbar-nav flex-column">
                    <NavItem name="Orders"
                             icon="fa-list"
                             route="orders"
                             v-if="$auth.hasPermissions('read:orders')" />
                    <NavItem name="Shipments"
                             icon="fa-dolly"
                             route="shipments"
                             v-if="$auth.hasPermissions('read:shipments')" />
                    <NavItem name="Products"
                             icon="fa-boxes"
                             route="products"
                             v-if="$auth.hasPermissions('read:products')" />
                </ul>
                <hr v-if="$auth.hasSomePermission('read:orders', 'read:shipments', 'read:products') &&
                    $auth.hasSomePermission('read:customers', 'read:manual_payments', 'read:payout_reports',
                                            'read:printers', 'read:package_dimensions')">
                <ul class="navbar-nav flex-column">
                    <NavItem name="Customers"
                             icon="fa-users"
                             route="customers"
                             v-if="$auth.hasPermissions('read:customers')" />
                    <NavItem name="Payments"
                             icon="fa-credit-card"
                             route="payments"
                             v-if="$auth.hasPermissions('read:manual_payments')" />
                    <NavItem name="Payout Reports"
                             icon="fa-money-bill-wave"
                             route="payout-reports"
                             v-if="$auth.hasPermissions('read:payout_reports')" />
                    <NavItem name="Printers"
                             icon="fa-print"
                             route="printers"
                             v-if="$auth.hasPermissions('read:printers')" />
                    <NavItem name="Package Presets"
                             icon="fa-ruler-combined"
                             route="package-presets"
                             v-if="$auth.hasPermissions('read:package_dimensions')" />
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
    import NavItem from './NavItem';

    export default {
        name: 'Navbar',
        components: {
            NavItem,
        },
        watch: {
            $route() {
                // Clears focus from nav links after a route change
                // This emulates the behavior of loading a new HTML document (what Falcon expects)
                this.$el.querySelectorAll('a.nav-link').forEach(el => el.blur());
            },
        },
    };
</script>
