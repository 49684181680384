import Vue from 'vue';

export default class AlertsManager {
    constructor() {
        // Stores internal state in an object that can be observed in Vue templates
        this._state = Vue.observable({
            alertStack: [],
        });
    }

    // Makes this class installable as a Vue plugin [Vue.use(AlertsManager)]
    static install(Vue) {
        Vue.prototype.$alerts = new AlertsManager();
    }

    /**
     * Shows an generic alert.
     * @param {string} title - A short title
     * @param {string} [message] - A longer descriptive message
     */
    message(title, message) {
        this._pushAlert(title, message, '');
    }

    /**
     * Shows an alert with a green title, representing success.
     * @param {string} title - A short title
     * @param {string} [message] - A longer descriptive message
     */
    success(title, message) {
        this._pushAlert(title, message, 'text-success');
    }

    /**
     * Shows an alert with an orange title, representing a warning.
     * @param {string} title - A short title
     * @param {string} [message] - A longer descriptive message
     */
    warning(title, message) {
        this._pushAlert(title, message, 'text-warning');
    }

    /**
     * Shows an alert with a red title, representing a serious error.
     * @param {string} title - A short title
     * @param {string} [message] - A longer descriptive message
     */
    danger(title, message) {
        this._pushAlert(title, message, 'text-danger');
    }

    /**
     * Pushes a new alert to the stack (internal)
     * @param {string} title - A short title
     * @param {string} [message] - A longer descriptive message
     * @param {string} [titleClass] - Class(es) applied to the title element
     */
    _pushAlert(title, message, titleClass) {
        this._state.alertStack.push({
            title,
            message,
            titleClass,
        });
    }

    /**
     * Removes the alert at the given index (internal)
     * @param {number} index - Index at which to remove
     */
    _dismissAlertAtIndex(index) {
        this._state.alertStack.splice(index, 1);
    }
}
