<template>
    <transition-group tag="div"
                      class="alert-container m-4 position-fixed b-0 r-0"
                      style="z-index: 2000;">
        <div v-for="(alert, index) in $alerts._state.alertStack"
             :key="alert._id"
             class="toast show"
             style="width: 350px;"
             role="alert">
            <div class="toast-header" :class="{ 'border-bottom-0': !alert.message }">
                <span class="mr-auto">
                    <strong :class="alert.titleClass">{{ alert.title }}</strong>
                </span>
                <button class="ml-2 mb-1 close"
                        @click="$alerts._dismissAlertAtIndex(index)"
                        type="button"
                        aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div v-if="alert.message" class="toast-body">{{ alert.message }}</div>
        </div>
    </transition-group>
</template>

<script>
    export default {
        name: 'AlertContainer',
        watch: {
            $route(to, from) {
                // Clear the alerts when the route changes
                // This component needs to be wrapped in <keep-alive> so this always runs
                if (to !== from) {
                    this.$alerts._state.alertStack = [];
                }
            },
        },
    };
</script>

<style scoped>
    .toast {
        /* Toasts usually apply margins to the bottom of all but the last one. This makes the animations jumpy,
           so we put the margins on the top of all the toasts instead. */
        margin-bottom: 0;
        margin-top: 0.75rem;
    }

    .v-enter {
        opacity: 0;
        transform: translateX(300px);
    }

    .v-enter-active {
        transition: opacity 0.6s, transform 0.6s;
    }

    .v-move {
        transition: transform 0.4s;
    }

    .v-leave-active {
        /* This is required for the move transitions to play on removal. It removes the element from flow, allowing
           the others to move into the new space. The regular way to do this is position: absolute, but that causes
           issues in a fixed-position context that aren't worth working around. */
        display: none;
    }
</style>
