import { authGuard, authRoutes } from './auth';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    ...authRoutes,
    {
        path: '/',
        redirect: { name: 'customers' },
    },
    {
        name: 'orders',
        path: '/orders',
        component: () => import('../views/Orders.vue'),
        meta: {
            requiredPermissions: ['read:orders'],
        },
    },
    {
        name: 'order-details',
        path: '/orders/:id',
        component: () => import('../views/OrderDetails.vue'),
        meta: {
            requiredPermissions: ['read:orders'],
        },
    },
    {
        name: 'shipments',
        path: '/shipments',
        component: () => import('../views/Shipments.vue'),
        meta: {
            requiredPermissions: ['read:shipments'],
        },
    },
    {
        name: 'shipment-details',
        path: '/shipments/:id',
        component: () => import('../views/ShipmentDetails.vue'),
        meta: {
            requiredPermissions: ['read:shipments'],
        },
    },
    {
        name: 'create-shipment',
        path: '/create-shipment',
        component: () => import('../views/CreateShipment/CreateShipment.vue'),
        meta: {
            requiredPermissions: ['create:shipments'],
        },
    },
    {
        name: 'products',
        path: '/products',
        component: () => import('../views/Products.vue'),
        meta: {
            requiredPermissions: ['read:products'],
        },
    },
    {
        name: 'customers',
        path: '/customers',
        component: () => import('../views/Customers.vue'),
        meta: {
            requiredPermissions: ['read:customers'],
        },
    },
    {
        name: 'create-customer',
        path: '/customers/create',
        component: () => import('../views/CreateCustomer.vue'),
        meta: {
            requiredPermissions: ['create:customers'],
        },
    },
    {
        path: '/customers/:id',
        component: () => import('../views/CustomerDetails.vue'),
        meta: {
            requiredPermissions: ['read:customers'],
        },
        children: [
            {
                name: 'customer-details',
                path: '',
                component: () => import('../components/CustomerDetails/CustomerDetailsCards.vue'),
            },
            {
                name: 'edit-customer',
                path: 'edit',
                component: () => import('../components/CustomerDetails/EditCustomer.vue'),
                meta: {
                    requiredPermissions: ['update:customers'],
                },
            },
        ],
    },
    {
        name: 'payments',
        path: '/payments',
        component: () => import('../views/Payments.vue'),
        meta: {
            requiredPermissions: ['read:manual_payments'],
        },
    },
    {
        name: 'create-payment',
        path: '/payments/create',
        component: () => import('../views/CreatePayment.vue'),
        meta: {
            requiredPermissions: ['create:manual_payments'],
        },
    },
    {
        path: '/payout-reports',
        component: () => import('../views/PayoutReports.vue'),
        meta: {
            requiredPermissions: ['read:payout_reports'],
        },
        children: [
            {
                name: 'payout-reports',
                path: '',
                component: () => import('../components/PayoutReports/PayoutReportsList.vue'),
            },
            {
                name: 'payout-report-details',
                path: ':id',
                component: () => import('../components/PayoutReports/PayoutReportDetails.vue'),
                meta: {
                    requiredPermissions: ['read:manual_payments'],
                },
            },
        ],
    },
    {
        name: 'printers',
        path: '/printers',
        component: () => import('../views/Printers.vue'),
        meta: {
            requiredPermissions: ['read:printers'],
        },
    },
    {
        name: 'create-printer',
        path: '/printers/create',
        component: () => import('../views/CreatePrinter.vue'),
        meta: {
            requiredPermissions: ['create:printers'],
        },
    },
    {
        name: 'package-presets',
        path: '/package-presets',
        component: () => import('../views/PackagePresets.vue'),
        meta: {
            requiredPermissions: ['read:package_dimensions'],
        },
    },
    {
        // Declaring a 404 route to negate following error when 404'ing from a route that requires a param
        // `missing param for named route expected "0" to be defined`
        name: '404',
        path: '/404',
        component: () => import('../views/404.vue'),
    },
    {
        path: '*',
        redirect: '404',
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(authGuard);

// Track page change events with umami
router.afterEach((to, from) => {
    /* global umami */
    if (typeof umami === 'function') {
        if (to.path !== from.path) {
            umami.trackView(to.path);
        }
    }
});

export default router;
