<template>
    <nav class="navbar navbar-light navbar-glass navbar-top sticky-kit navbar-expand">
        <button class="btn navbar-toggler-humburger-icon navbar-toggler mr-1 mr-sm-3 collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarVerticalCollapse"
                aria-controls="navbarVerticalCollapse"
                aria-expanded="false"
                aria-label="Toggle Navigation">
            <span class="navbar-toggle-icon">
                <span class="toggle-line" />
            </span>
        </button>
        <router-link :to="{ path: '/' }" class="navbar-brand">
            <div class="d-flex align-items-center">
                <img src="/img/logo_color.svg" height="42px">
            </div>
        </router-link>
        <ul class="navbar-nav navbar-nav-icons ml-auto flex-row align-items-center">
            <UserNavItem />
        </ul>
    </nav>
</template>

<script>
    import UserNavItem from './UserNavItem';

    export default {
        name: 'TopNavbar',
        components: {
            UserNavItem,
        },
    };
</script>
