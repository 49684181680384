/**
 * Formats a given numeric value to have a specific number of decimal digits
 * @param {number} value - Value to format
 * @param {number} maximumFractionDigits - Maximum number of digits after the decimal separator
 * @param {number} [minimumFractionDigits=0] - Minimum number of digits after the decimal separator
 * @param {boolean} [useGrouping=false] - Include grouping separators
 * @returns {string} The formatted value
 */
export const formatDecimal = (value, maximumFractionDigits, minimumFractionDigits = 0, useGrouping = false) => (
    (+value).toLocaleString(undefined, { maximumFractionDigits, minimumFractionDigits, useGrouping })
);
