import { formatCents, formatDollars } from './currency';
import { formatDate, formatISODate } from './date';
import { formatDecimal } from './number';

export { formatISODate, formatDate, formatDollars, formatCents, formatDecimal };

// Wraps the filters in a Vue plugin ( Vue.use() )
export default {
    install(Vue) {
        Vue.filter('formatCents', formatCents);
        Vue.filter('formatDollars', formatDollars);
        Vue.filter('formatDate', formatDate);
        Vue.filter('formatISODate', formatISODate);
        Vue.filter('formatDecimal', formatDecimal);
    },
};
