import './axios-interceptor'; // Import for side effects
import * as Sentry from '@sentry/vue';
import addIdentifier from '@medshift/object-id';
import AlertsManager from './alerts';
import App from './App.vue';
import { auth0 } from './auth0';
import directives from './directives';
import filters from './filters';
import router from './router';
import Vue from 'vue';

const DEBUG = process.env.NODE_ENV !== 'production';

if (!DEBUG) {
    Sentry.init({
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
        logErrors: true,
        Vue,
    });
}

Vue.config.productionTip = false;

// Main boot function for the application (useful because top-level await isn't a thing)
(async () => {
    addIdentifier(Object.prototype, '_id');

    Vue.use(filters);
    Vue.use(directives);

    Vue.use(await auth0);
    Vue.use(AlertsManager);

    new Vue({
        router,
        render: h => h(App),
    }).$mount('#app');
})();
