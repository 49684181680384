<template>
    <div id="app">
        <keep-alive>
            <AlertContainer />
        </keep-alive>
        <UserFeedback />
        <div class="container" data-layout="container">
            <Navbar v-if="$route.matched.length && !$route.meta.noNav" />
            <div class="content">
                <TopNavbar v-if="$route.matched.length && !$route.meta.noNav" />
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
    import AlertContainer from './components/AlertContainer';
    import Navbar from './components/Navbar';
    import TopNavbar from './components/TopNavbar';
    import UserFeedback from './components/UserFeedback';

    export default {
        components: {
            Navbar,
            TopNavbar,
            AlertContainer,
            UserFeedback,
        },
    };
</script>
